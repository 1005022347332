import { useAuthorization } from '~/composables/auth/useAuthorization';

/** Middleware, который скрывает страницы. */
export default defineNuxtRouteMiddleware(async () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    
    if(!isDevelopment) {
        const { redirectToLogoutUrl } = useAuthorization();
        redirectToLogoutUrl();
    }
})